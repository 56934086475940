import { AccountInfo } from "@azure/msal-browser";
import { Ref, unref } from "vue";

export interface MyAccountInfo {
    isAuthenticated: boolean
    account: AccountInfo | null
    userHasRoles: {
        id: string;
        role: string;
        label: string;
    }[]
    isRoleUser: boolean
    isRoleAdmin: boolean
}

export const getAccountInfo = (accounts: Ref<AccountInfo[]>|AccountInfo[]): MyAccountInfo => {
  const accountsUnrefed = unref(accounts)
  const isAuthenticated = accountsUnrefed.length > 0;
  const account = isAuthenticated ? accountsUnrefed[0] : null
  const roles = account?.idTokenClaims?.roles ?? []
  const roleMap = [
      {
          id: 'user',
          role: 'Positioning.User',
          label: 'User'
      },
      {
          id: 'admin',
          role: 'Positioning.Admin',
          label: 'Admin'
      }
  ];
  const userHasRoles = roleMap.filter(r=>roles?.includes(r.role))
  const isRoleUser = userHasRoles.filter(r=>r.id==='user').length > 0
  const isRoleAdmin = userHasRoles.filter(r=>r.id==='admin').length > 0

  return {
      isAuthenticated,
      account,
      userHasRoles,
      isRoleUser,
      isRoleAdmin
  }
}
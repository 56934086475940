import { Ref, ref, watch } from "vue";
import { useMsal } from "./useMsal";
import { getAccountInfo } from "@/utils/account";
import type { MyAccountInfo } from "@/utils/account";


export function useAccountInfo(): Ref<MyAccountInfo> {
    const { accounts } = useMsal();

    const accountData = ref(getAccountInfo(accounts))
    watch(accounts, () => {
        accountData.value = getAccountInfo(accounts)
    });

    return accountData
}
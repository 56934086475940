import { RouteLocationNormalized, Router } from "vue-router";
import { msalInstance, loginRequest } from "../authConfig";
import { PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { getAccountInfo } from "@/utils/account";

export function registerGuard(router: Router) {
    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        if (to.meta.requiresAuth) {
            
            const accountInfo = getAccountInfo(msalInstance.getAllAccounts())
            if ( accountInfo.isAuthenticated ) {
                if ( to.meta.requiresRole) {
                    return accountInfo.userHasRoles.find(r=>to.meta.requiresRole?.includes(r.id)) ? true : false
                }
                else {
                    return true
                }
            }
            else {
                // this is where the login is initiated
                const request = {
                    ...loginRequest,
                    redirectStartPage: to.fullPath
                }
                const shouldProceed = await isAuthenticated(msalInstance, request);
                return shouldProceed || '/failed';
            }
        }
    
        return true;
    });
}

export async function isAuthenticated(instance: PublicClientApplication, loginRequest: RedirectRequest): Promise<boolean> {    
    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not  
    return instance.handleRedirectPromise().then((response) => {
        // console.log('handleRedirectPromise', response)
        if (response !== null) {
            return true
        }
        else {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
                return true;
            }
            else {
                // User is not signed in and attempting to access protected route. Sign them in.
                return instance.loginRedirect(loginRequest).then(() => {
                    return true;
                }).catch(() => {
                    return false;
                });
            }
        }

        return false;
    }).catch(() => {
        return false;
    });
}